<template>
  <section class="hero">
    <div class="hero-body">
      <h1
        class="title has-text-centered"
        style="font-size: 1.6rem; font-weight: 600"
      >
        {{ fname }}'s Website
      </h1>
      <div class="banner">
        <span class="coins">
          <img src="@/assets/badge.png" alt />
        </span>
        <div class="userprofile" :style="picture"></div>
      </div>
      <div class="columns">
        <div class="column info">
          <h1 class="title">{{ name }}</h1>
          <h3 class="subtitle">{{ school }}</h3>
          <p>{{ bio }}</p>
        </div>
        <div class="column is-3">
          <hr class="is-hidden-tablet" />
          <h3 class="subtitle">Completed Projects</h3>
          <div class="tags">
            <span v-for="tag in tags" :key="tag.id" class="tag is-rounded">{{
              tag.name
            }}</span>
          </div>
        </div>
        <div class="column is-3">
          <div class="card is-fullwidth followers" @click="redirect()">
            <div class="card-content">
              <p class="title">{{ followers }}+</p>
              <p class="subtitle">
                Makers are following my innovations on WitBlox.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column">
              <h1 class="title has-text-centered">My Projects ({{ posts }})</h1>
            </div>
          </div>
          <div class="columns">
            <div class="column posts">
              <div v-for="post in projects" :key="post.id" class="card">
                <div class="card-image">
                  <figure class="image is-4by3">
                    <video
                      controls
                      :src="post.video"
                      :poster="post.thumbnail"
                    ></video>
                  </figure>
                </div>
                <div class="card-content">
                  <div class="hashtag">
                    <span class="badge"
                      >#{{ post.hashtag ? post.hashtag : "NewPost" }}</span
                    >
                  </div>
                  <div class="content">{{ post.caption }}</div>
                  <footer class="card-footer">
                    <p class="card-footer-item">
                      <i class="fas fa-heart"></i>
                      {{ post.likes }}
                    </p>
                    <p class="card-footer-item">
                      <i class="fas fa-eye"></i>
                      {{ post.views }}
                    </p>
                  </footer>
                </div>
              </div>
            </div>
          </div>
          <div
            v-observe-visibility="
              (isVisible, entry) => visibilityChanged(isVisible, entry)
            "
          ></div>
        </div>
      </div>
      <hr />
      <div class="ad" @click="redirect()">
        <img width="100%" src="@/assets/ad.jpeg" />
        <div class="title ad-text">
          Follow {{ fname }}'s innovations on WitBlox
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column">
          <p class="has-text-centered" style="font-size: 0.7rem">
            &copy; WitBlox &bull; We TechShiksha Private Limited
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { db } from "@/main.js";
import { functions } from "@/main.js";
export default {
  name: "profile",
  data() {
    return {
      uid: this.$route.params.uid,
      picture: "",
      fname: "",
      name: "",
      school: "",
      bio: "",
      coins: 0,
      followers: 0,
      posts: 0,
      tags: [],
      projects: [],
      lastpost: 0,
      projectCheck: [],
    };
  },
  methods: {
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        console.log(entry);
        this.fetchPosts(this.uid, this.lastpost);
      }
    },
    fetchPosts(uid, timestamp) {
      db.collection("social")
        .where("uid", "==", this.uid)
        .where("created", "<", timestamp)
        .orderBy("created", "desc")
        .limit(10)
        .get()
        .then((snap) => {
          snap.forEach((post) => {
            if (!this.projectCheck.includes(post.id)) {
              this.projectCheck.push(post.id);
              db.collection("social")
                .doc(post.id)
                .collection("likes")
                .get()
                .then((likes) => {
                  this.lastpost = post.data().created;
                  this.projects.push({
                    id: post.id,
                    hashtag: post.data().hashtag,
                    video: post.data().url,
                    thumbnail:
                      "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
                      encodeURIComponent(post.data().url) +
                      "&size=500&mode=crop",
                    caption: post.data().caption,
                    likes: likes.size,
                    views: post.data().views,
                  });
                });
            }
          });
        });
    },
    redirect() {
      window.location.href = `https://app.witblox.com/u/${this.uid}`;
    },
  },
  beforeMount() {
    this.fetchPosts(this.uid, new Date().getTime());
    var profileStats = functions.httpsCallable("profileStats");
    profileStats({ uid: this.uid }).then((result) => {
      this.posts = result.data.posts;
      this.followers = result.data.followers;
    });
    db.collection("users")
      .doc(this.uid)
      .get()
      .then((user) => {
        this.name = user.data().fname + " " + user.data().lname;
        this.picture = "background: url(" + user.data().picture + ")";
        this.school = user.data().school;
        this.bio = user.data().bio;
        this.fname = user.data().fname;
        document.title = this.fname + "'s Website";
      });
    db.collection("users")
      .doc(this.uid)
      .collection("projects")
      .where("type", "==", "story")
      .get()
      .then((snap) => {
        snap.forEach((tag) => {
          db.collection("projects")
            .doc(tag.data().pid)
            .get()
            .then((project) => {
              this.tags.push({ id: project.id, name: project.data().name });
            });
        });
      });
  },
  mounted() {
    console.log(this.uid);
  },
};
</script>

<style>
.hero-body {
  padding: 1.5rem 1.5rem 3rem 1.5rem;
}
.banner {
  height: 200px;
  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  position: relative;
  margin-bottom: 50px;
}
.userprofile {
  height: 150px;
  width: 150px;
  background-size: cover !important;
  background: #ffffff;
  border: 4px solid #363636;
  border-radius: 50%;
  position: absolute;
  bottom: -40px;
  left: 20px;
}
.coins {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  /* background: #ffffff; */
  /* margin: 20px; */
  /* padding: 3px 15px; */
  /* border-radius: 25px; */
  position: absolute;
  top: 0;
  right: 0;
  width: 130px;
  margin: 10px;
}
.title {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 1.4rem;
}
.info {
  padding-top: 35px;
}
.subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.6rem !important;
}
p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.card-content {
  padding: 1rem;
}
.card-content i {
  margin-right: 5px;
}
.content {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
  width: 100%;
}
.card-footer-item {
  font-size: 0.9rem;
}
.image video {
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.followers.card {
  background: #8445e2;
}
.followers .title,
.followers .subtitle {
  color: #ffffff;
}
hr {
  background-color: #62626273;
}
.ad {
  position: relative;
}
.ad-text {
  position: absolute;
  left: 0;
  top: 0;
  height: 98%;
  width: 60%;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 56%,
    rgba(0, 0, 0, 0.65) 100%
  );
  color: #fff;
  padding: 8%;
  font-size: 2.5rem;
}
.posts .card {
  margin: 5px;
  width: 23.5%;
  display: inline-block;
}

.hashtag {
  font-weight: 600;
  margin-bottom: 1rem;
}

.badge {
  background-color: #363636;
  color: #fff;
  font-size: 0.7rem;
  margin-top: 5px;
  border-radius: 4px;
  padding: 0.5em 0.75em;
}

@media only screen and (max-width: 800px) {
  .posts .card {
    width: 48%;
    display: inline-block;
  }
  .ad-text {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 550px) {
  .posts .card {
    width: 100%;
    display: inline-block;
  }
  .ad-text {
    font-size: 1rem;
  }
}
</style>
