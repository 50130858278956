import Vue from 'vue'
import App from './App.vue'
import Router from "vue-router"

import "./assets/css/bulma.min.css"
import "./assets/css/all.min.css"
import "./assets/css/fonts.css"

import profile from "./components/profile.vue"

import { ObserveVisibility } from 'vue-observe-visibility'
import VueFire from "vuefire"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import "firebase/storage"

Vue.use(Router, VueFire)
Vue.directive('observe-visibility', ObserveVisibility)

const firebaseConfig = {
  apiKey: "AIzaSyCQOZ2Re2ytVpz7KiKhqrQcV3GNG9r8pZk",
  authDomain: "witblox-5ae68.firebaseapp.com",
  databaseURL: "https://witblox-5ae68.firebaseio.com",
  projectId: "witblox-5ae68",
  storageBucket: "witblox-5ae68.appspot.com",
  messagingSenderId: "532604685774",
  appId: "1:532604685774:web:a895ff5bd01df136edaf9f",
  measurementId: "G-YKYYG1KZKB"
}
firebase.initializeApp(firebaseConfig)
export const db = firebase.firestore()
export const auth = firebase.auth()
export const functions = firebase.functions()
export const storage = firebase.storage()

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/:uid",
      name: "profile",
      component: profile,
    },
  ]
})

let app
if (!app) {
  app = new Vue({
    router,
    render: h => h(App)
  }).$mount("#app")
}

Vue.config.productionTip = false
